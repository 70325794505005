@import "../../InQba.scss";

.flip-card {
  text-align: center;
  width: 290px;
  height: 400px;
  background-color: transparent;
  margin-bottom: 30px;
  z-index: 2;
  perspective: 1000px;
  &.gold {
    .flip-card__ver-mas {
      color: $amarillo;
    }
    .flip-card__linea {
      border-bottom: 3px solid $amarillo;
    }
    .flip-card__especialidad-back {
      color: $amarillo;
    }
    .flip-card__foto {
      border: 4px solid $amarillo;
    }
    svg {
      fill: $amarillo;
    }
  }

  .flip {
    transform: rotateY(180deg);
  }

  @include tablet {
    width: 330px;
  }

  @media (min-width: 1066px) and (max-width: 1150px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @include laptop {
    margin-left: 24px;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &__back,
  &__front {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 30px 30px 24px;
    border: 1px solid #d7d7dd;
  }

  &__front {
    background-color: #f6f6f6;
    color: black;

    &:hover {
      background-color: $blanco;
      border: none;
    }
  }

  &__back {
    background-color: $blanco;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    transform: rotateY(180deg);
    &-scrollable {
      justify-content: start;
      max-height: 300px;
      overflow: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
        // background-color: transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gris;
        border-radius: 10px;
        // height: 10px;
        // background-color: ;
      }
    }
  }

  &__linea {
    width: 110px;
    border-bottom: 3px solid $verde;
    margin-top: 25px;
  }

  &__foto {
    margin: auto;
    min-height: 164px;
    min-width: 164px;
    height: 164px;
    width: 164px;
    border-radius: 100%;
    border: 4px solid $verde;
    background-image: url("../../assets/img/foto-prueba-blanca.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__estrellas {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 15px;
    width: 100px;
    margin-top: 25px;
  }

  &__nombre {
    margin-top: 5px;
    color: $gris-fuerte;
  }

  &__puesto {
    margin-top: 5px;
    color: $gris-fuerte;
    max-width: 250px;
  }

  &__perfil-back {
    margin-top: 25px;
    font-size: 14px;
    color: $gris-fuerte;
    width: 100%;
    word-wrap: break-word;
    max-width: 100%;
  }

  &__cursos {
    margin-top: 15px;
    color: $gris-fuerte;
  }

  &__press-icon {
    height: 17.45px;
    width: 17.45px;
    margin-right: 5px;
  }

  &__press-icon-back {
    height: 17.45px;
    width: 17.45px;
    margin-top: 25px;
  }

  &__ver-mas {
    margin-top: 15px;
    text-align: center;
    cursor: pointer;
  }

  &__especialidad {
    text-align: center;
  }

  &__especialidad-back {
    text-align: center;
    color: $verde;
    max-width: 100%;
    width: 100%;
    word-wrap: break-word;
  }

  &__refresh-arrows {
    margin-top: 25px;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}
