.admin-cursos {
  padding: 30px 40px;
  &__titulo {
    text-align: left;
  }
  &__content {
    margin-left: 20px;
  }
  &__subtitulo {
    font-weight: 500;
  }
}
