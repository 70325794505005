@import "../../InQba.scss";

.unique-enfoques {
  padding: 40px 0 0;
  &.green {
    .unique-enfoques__item {
      svg {
        fill: $verde;
        path {
          fill: $verde;
        }
        circle {
          fill: $verde;
        }
      }
    }
  }

  @include tablet {
    padding-top: 60px;
  }
  @include laptop {
    padding-top: 100px;
  }
  &__title {
    color: $gris-fuerte;
    padding: 0 16px;
    @include tablet {
      text-align: center;
      padding: 0;
      width: 363px;
      margin: auto;
    }
  }
  &__carousel {
    padding-top: 30px;
    overflow: hidden;
    width: 100%;
    margin: auto;
    @include tablet {
      padding-top: 60px;
    }
  }
  &__slider {
    margin: auto;
    width: 288px;
    display: flex;
    flex-direction: row;
    transform: translateX(calc((100vw + 288px) * -1));
    height: 427px;
    @include tablet {
      width: 397px;
      transform: translateX(calc((100vw + 397px) * -1));
    }
    @include laptop {
      width: 818px;
      transform: translateX(calc((100vw + 818px) * -1));
    }
    @include desktop {
      width: 1239px;
      transform: translateX(calc((100vw + 1239px) * -1));
    }

    &.center {
      display: flex;
      justify-content: center;
      transform: none !important;
      .unique-enfoques__item {
        margin-right: 0;
        @include tablet {
          min-width: 397px;
          width: 397px;
        }
        @include laptop {
          margin: 10px 24px 0 0;
          &:nth-child(2n) {
            margin: 10px 24px 0 0;
          }
        }
        @include desktop {
          &:nth-child(2n) {
            margin: 10px 24px 0 0;
          }

          &:nth-child(3n) {
            margin: 10px 24px 0 0;
          }
        }
      }
    }
  }
  &__item {
    margin: 10px 100vw 0 0;
    padding: 60px 0;
    min-width: 288px;
    width: 288px;
    height: 397px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $blanco;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    @include tablet {
      min-width: 397px;
      width: 397px;
    }
    @include laptop {
      margin: 10px 24px 0 0;
      &:nth-child(2n) {
        margin: 10px 100vw 0 0;
      }
    }
    @include desktop {
      &:nth-child(2n) {
        margin: 10px 24px 0 0;
      }

      &:nth-child(3n) {
        margin: 10px 100vw 0 0;
      }
    }

    &.spacer {
      opacity: 0;
    }
  }
  &__itemTitle {
    margin: 0 0 24px;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: $gris-fuerte;
  }

  &__bullets {
    width: 100%;
    padding: 0 16px;
    max-height: 353px;
    overflow: auto;
    @include tablet {
      padding: 0 24px;
    }
  }
  &__bullet {
    display: flex;
    align-items: flex-start;
    svg {
      min-width: 7px;
      transform: translateY(12px);
    }
    span {
      padding-left: 12px;
      color: $gris-fuerte;
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 86px;
    margin: auto;
    padding-top: 30px;
    @include tablet {
      padding-top: 60px;
    }
    button {
      background: $gris;
      border: none;
      width: 38px;
      height: 38px;
      padding: 0;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin: auto 12px auto auto;
      }
      &:first-child {
        svg {
          transform: rotate(180deg);
          margin: auto auto auto 12px;
        }
      }
    }
  }
}
