@import "../../InQba.scss";

.unique-header {
  min-height: calc(100vh - 99.14px);
  margin-top: 99.14px;
  width: 100%;

  &.mba {
    background-color: rgba(223, 199, 71, 0.9);
  }

  &.diplomados {
    background-color: $verde;
    .unique-header__button {
      color: $verde;
    }
  }

  &.cursos {
    background-color: $negro;
    .unique-header__button {
      color: $blanco;
      background-color: $verde;
    }
  }

  @include tablet {
    min-height: calc(100vh - 130.41px);
    margin-top: 130.41px;
  }

  @include laptop {
    min-height: 450px;
  }

  &__container {
    position: relative;
    height: auto;
    min-height: inherit;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include tablet {
      width: 688px;
      margin: 130.41px auto 0;
      justify-content: center;
    }

    @include laptop {
      width: 948px;
      padding: 0;
      justify-content: flex-start;
    }

    @include desktop {
      width: 1338px;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    padding-top: 30px;
    text-decoration: none;
    width: fit-content;
    @include tablet {
      padding-top: 0;
      position: absolute;
      top: 24px;
      left: 0;
    }
    @include laptop {
      position: initial;
      padding-top: 30px;
    }

    span {
      color: $blanco;
      font-weight: bold;
      margin-left: 13px;
      font-size: 16px;
      line-height: 30px;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 46px;
    word-wrap: break-word;
    color: $blanco;
    margin-top: 40px;
    max-width: 393px;
    @include tablet {
      max-width: none;
      font-size: 60px;
      line-height: 70px;
      width: 100%;
      margin-top: 0;
    }
    @include laptop {
      width: 761px;
      margin-top: 40px;
      margin-left: 47px;
    }
  }

  &__subtitle {
    display: block;
    font-family: $roboto;
    font-weight: 300;
    font-size: 25px;
    margin-top: 24px;
    color: $blanco;
    max-width: 393px;

    @include tablet {
      width: 100%;
      max-width: none;
      padding-right: 98px;
    }
    @include laptop {
      margin-left: 47px;
      padding-right: 400px;
    }
    @include desktop {
      padding-right: 500px;
    }
  }

  &__button {
    background: $blanco;
    color: $amarillo;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    width: 100%;
    padding: 18px 0;
    border: none;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    border-radius: 7px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    text-decoration: none;
    margin-bottom: 40px;

    @include tablet {
      width: 238px;
      position: absolute;
      bottom: 60px;
      margin-bottom: 0;
      right: 40px;
    }
    @include laptop {
      right: 0;
    }
  }
}
