@import "../../InQba.scss";

.box-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  width: 288px;
  height: 150px;
  min-height: 150px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  word-break: break-word;
  @include tablet {
    width: 402px;
  }
  @include laptop {
    width: 402px;
  }

  &__title {
    font-weight: bold;
    font-size: 40px;
    font-family: $roboto;
    text-align: center;
    @include tablet {
      font-size: 60px;
    }
    @include laptop {
      font-size: 60px;
    }
  }
}
