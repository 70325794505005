@import "../../InQba.scss";

.mba-home {
  background-color: $blanco;
  &__ribbon {
    position: relative;
    width: 100%;
    background-color: $amarillo;
    height: 10px;
    display: block;

    @include mobileMinus {
      margin: 0 0 40px;
    }

    svg {
      position: absolute;
      left: calc(50% - 50px);
      right: 0;
      top: -45px;
      bottom: 0px;
      text-align: center;
    }
  }

  .generic-links {
    margin-top: 40px;
  }

  &__title {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    color: $gris-fuerte;
    padding: 20px 16px 0;
    @include tablet {
      padding: 100px 40px 0;
    }
    @include laptop {
      padding: 150px 100px 0;
    }
    @include desktop {
      max-width: 1140px;
      margin: auto;
      padding: 150px 0 0;
    }
  }

  &__grid {
    margin: 40px 0 0;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 40px;
      grid-column-gap: 28px;
      grid-row-gap: 60px;
      margin: 60px 0 0;
    }

    @include laptop {
      padding: 100px 0 0;
      max-width: 740px;
      margin: auto;
      margin-bottom: 170px;
    }
    @include desktop {
      // grid-template-columns: repeat(3, 1fr);
      max-width: 1136px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      // justify-items: center;
      // align-content: center;
      // justify-content: center;
      // align-items: center;
    }
  }

  &__grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    @include tablet {
      margin-bottom: 0;
    }
    @include desktop {
      width: 330px;
    }

    span {
      color: $gris-fuerte;
      margin-top: 24px;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .tenemos {
    @include tablet {
      padding-top: 0;
    }
    @include laptop {
      padding-top: 100px;
    }
    @include desktop {
      padding-top: 60px;
    }

    &__fondo {
      @include tablet {
        padding-top: 0;
      }
    }
    &__fondo__profesores-cards {
      padding-top: 20px;
    }
  }
}
