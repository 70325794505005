@import "../../InQba.scss";

.generic-links {
  color: $gris-fuerte;
  padding: 40px 16px 40px;

  @include tablet {
    padding: 60px 40px 40px;
  }

  @include laptop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 100px 100px 40px;
  }

  @include desktop {
    justify-content: space-evenly;
    padding-left: 260px;
    max-width: 1440px;
    margin: auto;
    padding: 150px 100px 40px;
  }

  &__inscribete {
    @include laptop {
      width: 405px;
    }

    h3 {
      font-weight: 500;
      font-size: 30px;
      line-height: 30px;
    }

    p {
      font-weight: 300;
      margin-top: 20px;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;

      @include tablet {
        width: 374px;
        margin-bottom: 25px;
      }
    }

    .inscribete {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: $verde;
      display: inline-block;
      cursor: pointer;

      &.gold {
        color: $amarillo;
      }
      :hover {
        opacity: 0.5;
      }
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
      align-items: flex-start;
    }

    @include laptop {
      :nth-child(1) {
        align-self: flex-end;
      }

      :nth-child(2) {
        align-self: flex-end;
        margin-right: 90px;
      }

      :nth-child(3) {
        align-self: flex-start;
      }

      :nth-child(4) {
        align-self: flex-end;
        margin-right: 30px;
      }

      :nth-child(5) {
        align-self: flex-start;
        margin-bottom: 40px;
      }
    }

    a {
      text-decoration: none;
      color: $gris-fuerte;
    }

    &-card {
      margin-top: 25px;
      padding: 10px;
      width: 100%;
      background: $blanco;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @include tablet {
        display: inline-block;
        width: auto;
        margin-top: 40px;
        height: 50px;
      }

      :hover {
        opacity: 0.5;
      }

      &__span {
        font-size: 20px;
        @include tablet {
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          vertical-align: middle;
        }
      }

      .flecha {
        margin-right: 10px;
        flex-shrink: 0;

        @include tablet {
          margin-left: 20px;
          height: 17px;
          vertical-align: middle;
        }
      }
    }
  }
}
