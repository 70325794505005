@import "../../InQba.scss";

.error-page {
  width: 100%;
  height: 100vh;
  padding: 0 16px 0;
  background-color: $blanco;

  &__image {
    width: 180px;
    height: auto;

    @include tablet {
      width: 347px;
    }

    @include desktop {
      width: 488px;
    }
  }

  &__header {
    margin: 40px auto 0;
    font-weight: 700;
    text-align: center;
    line-height: 25px;

    @include tablet {
      margin-top: 60px;
    }
  }

  &__paragraph {
    margin: 24px auto 0;
    font-family: $roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    @include tablet {
      margin-top: 15px;
    }
  }

  &__enlace {
    margin: 0 5px 0;
    text-decoration: none;
    font-size: 24px;

    @include tablet {
      font-size: 28px;
    }

    @include laptop {
      font-size: 24px;
    }
  }
}
