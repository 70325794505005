@import "../../InQba.scss";

.admin-profesores {
  padding: 30px 40px 100px;
  scroll-behavior: smooth;
  min-height: 100vh;
  height: auto;
  background-color: $blanco;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &__contenedor {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;

    .admin-profesores__titulo {
      font-size: 25px;
    }

    div {
      justify-self: start;
      margin-left: 20px;

      div {
        margin-left: 0;
      }
    }

    button {
      justify-self: end;
    }
  }

  &__contenido {
    > div:first-of-type {
      margin-top: 30px;
    }
  }

  &__boton {
    position: absolute;
    top: 55px;
    right: 40px;
  }
}
