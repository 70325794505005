.admin-eventos {
  margin: 30px 40px;
  position: relative;
  min-height: 100px;
  &__titulo {
    font-size: 25px;
    text-align: left;
  }
  &__content {
    margin-left: 20px;
  }
  &__add {
    position: absolute;
    top: 40px;
    right: 0;
  }
}

.evento-especifico {
  &__imagen {
    position: relative;
    svg {
      position: absolute;
      top: 0;
      right: 0;
      left: calc(405px - 18px);
      cursor: pointer;
    }
  }
  &__inputIcon {
    max-width: calc(405px - 37px);
  }
}
