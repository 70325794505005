@import "../../InQba.scss";

.evento-modal {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    padding: 25px 16px 40px;
    margin: 0 16px;
    svg {
      align-self: flex-end;
      cursor: pointer;
    }
    @include tablet {
      width: 687px;
      padding: 25px 25px 60px;
    }
  }

  &__title {
    font-weight: 300;
    margin-top: 22px;
    color: $blanco;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    @include tablet {
      font-size: 30px;
    }
  }

  &__subtitle {
    font-family: $montserrat;
    color: $blanco;
    margin-top: 20px;
    font-style: normal;
    font-weight: 250;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    @include tablet {
      font-size: 20px;
    }
  }

  &__form {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include tablet {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 565px;
    }
  }

  &__input {
    width: 100%;
    margin-bottom: 10px;
    padding: 14px 16px;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;

    @include tablet {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__boton {
    background-color: $verde;
    color: $blanco;
    border: none;
    width: 100%;
    height: 48px;
    font-family: $montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;

    &.back {
      height: 66px;
      margin-top: 20px;
      width: 238px;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      @include tablet {
        margin-top: 40px;
      }
    }
  }
}
