@import "../../InQba.scss";

#barra-lateral-admin {
  .logo-barra {
    background: #f7f7f7;
    border-right: 1px solid #c4c4c4;

    .logo-inQba {
      margin: 20px 0px 24px 40px;
    }
  }

  .contenido {
    padding: 24px 0 0 0;
    background: #f7f7f7;
    border-right: 1px solid #c4c4c4;
    height: 100%;

    &__span {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      color: #000000;
      padding-left: 40px;
    }

    ul {
      list-style: none;
      margin: 25px 0 0;
      padding: 0;

      li {
        height: 55px;

        a {
          text-decoration: none;
          width: 100%;
          height: 100%;
          color: black;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 40px;

          &:hover {
            background-color: $gris-claro;
          }

          &.active {
            background-color: #c4c4c4;
          }

          div {
            display: flex;

            img.icono {
              margin-right: 10px;
            }

            span {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
