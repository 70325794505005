@import "../../InQba.scss";

.admin-perfil {
  width: 726px;
  margin: 40px auto;

  &__card {
    padding: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 12px;

    color: #000000;

    mix-blend-mode: normal;
    &.--standalone {
      margin-bottom: 24px;
    }
  }

  &__field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    &.mb {
      margin-bottom: 36px;
    }
  }

  &__fieldLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    margin-right: 10px;
  }

  &__fieldContent {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__button {
    padding: 10px 16px;
    border-radius: 30px;
    background-color: $negro;
    border: none;
    color: $blanco;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;

    &:first-child {
      margin-right: 12px;
    }

    &.--white {
      background-color: $blanco;
      color: $negro;
      border: 2px solid $negro;
    }

    &.--transparent {
      background-color: transparent;
      color: $negro;
      font-size: 14px;
    }
  }
}
