@import "../../InQba.scss";

.generic-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $blanco;
  padding: 130px 16px;
  position: relative;
  background-position: top;
  @media (max-height: 590px) {
    height: 110vh;
  }

  &.diplomados {
    background-image: url("../../assets/img/hero-diplomados-320.jpg");
    @include tablet {
      background-image: url("../../assets/img/hero-diplomados-768.jpg");
    }

    @include laptop {
      background-image: url("../../assets/img/hero-diplomados-1152.jpg");
    }

    @include desktop {
      background-image: url("../../assets/img/hero-diplomados-1440.jpg");
    }
  }

  &.cursos {
    background-image: url("../../assets/img/hero-cursos-320.jpg");
    @include tablet {
      background-image: url("../../assets/img/hero-cursos-768.jpg");
    }

    @include laptop {
      background-image: url("../../assets/img/hero-cursos-1152.jpg");
    }

    @include desktop {
      background-image: url("../../assets/img/hero-cursos-1440.jpg");
    }
  }

  &.mba {
    background-image: url("../../assets/img/hero-mba-320.jpg");
    @include tablet {
      background-image: url("../../assets/img/hero-mba-768.jpg");
    }

    @include laptop {
      background-image: url("../../assets/img/hero-mba-1152.jpg");
    }

    @include desktop {
      background-image: url("../../assets/img/hero-mba-1440.jpg");
    }
  }

  .inscribete {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: $verde;
    margin-top: 24px;
    display: inline-block;
    cursor: pointer;
    &.gold {
      color: $amarillo;
    }

    @include tablet {
      margin-top: 25px;
    }

    :hover {
      opacity: 0.5;
    }
  }

  &__subtitle {
    margin-top: 24px;
    text-align: center;
    @include laptop {
      width: 873px;
      font-size: 25px;
    }
  }

  &__mbaSubtitle {
    margin-top: 24px;
    font-weight: bold;
    font-size: 20px;
  }
}
