@import "../../InQba.scss";

.admin-home {
  padding: 30px 40px 100px;
  scroll-behavior: smooth;
  background-color: $blanco;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &__mbas {
    margin-top: 30px;
  }

  &__categorias {
    position: relative;
    min-height: 40px;
    &--title {
      font-weight: normal;
    }
    &--add {
      position: absolute;
      top: 0;
      bottom: auto;
      left: auto;
      right: 0;
    }
  }

  &__profesores {
    display: grid;
    grid-template-columns: 4fr 2fr;
    justify-items: center;
    align-items: start;
    margin-top: 30px;

    div {
      justify-self: start;
      margin-left: 10px;

      div {
        margin-left: 0;

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
        }
      }
    }

    button {
      justify-self: end;
    }
  }
}
