@import "../../InQba.scss";

.contenedor-contacto {
  height: auto;
  width: 100%;
  background-image: url("../../assets/img/hero-contacto-320.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 16px 60px;

  @include tablet {
    background-image: url("../../assets/img/hero-contacto-768.jpg");
    padding: 177px 40px 60px;
  }

  @include laptop {
    background-image: url("../../assets/img/hero-contacto-1152.jpg");
    padding: 177px 100px 60px;
  }

  @include desktop {
    background-image: url("../../assets/img/hero-contacto-1440.jpg");
    padding: 177px 140px 60px;
  }

  &__seccion-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
      align-items: flex-start;
    }
  }

  &__seccion-gracias {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $blanco;
    min-height: 614px;

    @include laptop {
      min-height: 493px;
    }
    h2 {
      text-align: center;
      max-width: 500px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;

    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 278px);
      grid-gap: 10px;
      justify-content: start;

      :nth-child(7) {
        grid-column: span 2;
      }
    }
  }

  .form__input {
    width: 100%;
    height: 47px;
    margin-bottom: 10px;
    padding: 14px 12px 16px;
    background-color: $blanco;
    border: none;
  }

  select.form__input {
    padding-top: 0;
    padding-bottom: 0;
    &:not(:valid) {
      color: rgb(117, 117, 117);
    }
  }

  .form__textarea {
    width: 100%;
    height: 97px;
    margin-bottom: 10px;
    padding: 14px 12px 16px;
    background-color: $blanco;
    resize: none;
    border: none;

    @include tablet {
      grid-column: span 2;
    }
  }

  .form__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 18px;
    grid-column: span 2;
    margin-bottom: 10px;
    label {
      padding-left: 10px;
      a {
        color: white;
        text-decoration: none;
        border-bottom: 1px dotted white;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .form__btn-submit {
    background-color: $verde;
    width: 100%;
    height: 48px;
    text-align: center;
    border: none;

    @include tablet {
      grid-column: span 2;
    }
  }

  &__seccion-contactanos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;

    @include tablet {
      margin-top: 40px;
    }
  }

  .contenedor {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;
  }

  .contenedor__span {
    margin-left: 10px;
  }

  .ver-google {
    margin-top: 20px;
    margin-left: 30px;

    @include tablet {
      margin-top: 5px;
    }
  }
}
