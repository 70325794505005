@import "../../InQba.scss";

.footer {
  &-hero {
    background-image: url("../../assets/img/hero-footer-320.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60vh;
    padding: 0 16px;
    position: relative;
    z-index: 3;

    @include tablet {
      background-image: url("../../assets/img/hero-footer-768.jpg");
      height: 300px;
      padding: 0 40px;
    }

    @include laptop {
      background-image: url("../../assets/img/hero-footer-1440.jpg");
    }

    &__h2 {
      font-weight: 400;
      font-size: 30px;
      line-height: 30px;
      color: $blanco;
    }

    &__span {
      margin-top: 40px;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: $verde;
      cursor: pointer;

      @include tablet {
        margin-top: 60px;
      }

      @include laptop {
        margin-top: 40px;
      }

      :hover {
        opacity: 0.8;
      }
    }
  }

  &-insite {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 90px;
    background-color: $negro;

    @include tablet {
      padding: 25px 40px;
    }

    @include laptop {
      padding: 25px 100px;
    }

    &__contenedor-uno {
      @include tablet {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 60px;
      }

      &__inqba-logo {
        width: 135px;
        height: auto;
        margin: 0 auto;
        cursor: pointer;

        :hover {
          opacity: 0.8;
        }
      }

      &__hr {
        display: none;

        @include tablet {
          display: block;
          margin-left: 15px;
          border-top: 1px solid #ffffff;
          width: 70vw;
          height: 24.33px;
          align-self: flex-end;
        }

        @include laptop {
          width: 67vw;
        }

        @include desktop {
          width: 75vw;
        }
      }
    }

    &__contenedor-dos {
      margin: 25px 0 0;

      @include tablet {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &-element {
          margin-bottom: 10px;
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
          text-align: center;

          @include tablet {
            float: left;
            margin: auto 10px;
          }

          a {
            text-decoration: none;
            color: $blanco;
          }
        }
      }

      &__redes {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 25px;

        @include tablet {
          margin-top: 0;
        }

        &-facebook {
          width: 25px;
          height: 25px;
          cursor: pointer;

          :hover {
            opacity: 0.5;
          }
        }

        &-instagram {
          width: 25px;
          height: 25px;
          cursor: pointer;

          :hover {
            opacity: 0.5;
          }

          @include tablet {
            margin-left: 15px;
          }
        }
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $blanco;
    padding: 25px 20px;

    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include laptop {
      grid-template-columns: 2fr 1fr;
      padding: 14px 100px;
    }

    &__contuno {
      @include tablet {
        justify-self: start;
        margin-left: 40px;
      }

      @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
      }

      &__derechos {
        margin: 0 auto;
      }

      &__devby {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include tablet {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
        }

        @include laptop {
          margin-top: 2px;
          margin-left: 5px;
        }

        @include desktop {
          margin-top: 0px;
        }

        &__developed {
          margin: 0 auto;
        }

        &__logos {
          width: 100%;
          height: auto;
          margin: 0 auto;

          @include tablet {
            margin-left: 5px;
          }
        }
      }
    }

    &__aviso {
      margin: 10px auto 0;

      @include tablet {
        margin: auto;
        margin-right: 40px;
      }

      @include laptop {
        margin-right: 0px;
      }
    }
  }
}
