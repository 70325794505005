.admin-mba {
  padding: 30px 40px;
  &__titulo {
    text-align: left;
  }
  &__content {
    margin-left: 20px;
  }
}

.mba-page {
  padding: 60px 40px;
  &__otros {
    .collection-list {
      margin-top: 30px;
    }
  }
  &__listSelect {
    margin-top: 30px;
  }
}
