@import "../../InQba.scss";

.card-profesor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  cursor: pointer;

  &--par {
    @include tablet {
      grid-row: span 4;
      grid-column: 2 / 5;
    }
  }

  &--non {
    @include tablet {
      grid-row: span 4;
      grid-column: 6 / 9;
    }
  }

  &__foto {
    margin: auto;
    height: 230px;
    width: 230px;
    border-radius: 100%;
    box-shadow: (0px 10px 20px rgba(0, 0, 0, 0.15));
    background-image: url("../../assets/img/foto-prueba-blanca.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__estrellas {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 15px;
    width: 100px;
    margin-top: 25px;
  }

  &__nombre {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: $blanco;
    margin-top: 10px;
  }

  &__puesto {
    font-weight: 250;
    font-size: 16px;
    line-height: 30px;
    color: $blanco;
  }

  &__nombre,
  &__puesto {
    &.gris {
      color: $gris-fuerte;
    }
  }
}
