@import "../../InQba.scss";

.home {
  &__hero-image {
    background-image: url("../../assets/img/hero-home-320.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $blanco;
    padding: 130px 16px;
    position: relative;
    background-position: top;

    @include mobileMinus {
      height: auto;
    }

    @include tablet {
      background-image: url("../../assets/img/hero-home-768.jpg");
      padding: 130px 40px;
    }

    @include laptop {
      background-image: url("../../assets/img/hero-home-1152.jpg");
    }

    @include desktop {
      background-image: url("../../assets/img/hero-home-1440.jpg");
      padding: 130px 140px;
    }

    h1 {
      font-family: $roboto;
      font-weight: 700;
      font-size: 60px;
      line-height: 70px;

      @include tablet {
        font-family: $roboto;
        width: 590px;
      }
    }

    p {
      font-family: $roboto;
      font-weight: 300;
      font-size: 25px;
      line-height: 40px;
      margin-top: 10px;

      @include tablet {
        margin-top: 25px;
      }

      @include laptop {
        width: 620px;
        font-style: normal;
        font-weight: 300;
        font-size: 25px;
        line-height: 30px;
      }
    }

    .inscribete {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: $verde;
      margin-top: 10px;
      display: inline-block;
      cursor: pointer;

      @include tablet {
        margin-top: 25px;
      }

      :hover {
        opacity: 0.5;
      }
    }

    &__dos {
      z-index: 2;
      position: relative;
      margin-top: -25%;
      width: 100%;
      height: auto;

      @include tablet {
        margin-top: -15%;
      }

      @include laptop {
        margin-top: -5%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      &__desaparece-mobile {
        display: none;

        @include laptop {
          text-align: end;
          display: block;
          margin-top: 150px;
        }
        @include laptop {
          margin-right: 50px;
        }

        h3 {
          font-weight: 500;
          font-size: 30px;
          line-height: 30px;

          @include tablet {
            padding: 0 40px;
          }
        }

        &__p {
          font-weight: 300;
          margin-top: 20px;
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 10px;

          @include tablet {
            width: 410px;
            margin-bottom: 25px;
            padding: 0 40px;
            box-sizing: content-box;
          }

          @include laptop {
            padding: 0 50px;
          }
        }

        .inscribete {
          font-weight: 700;
          font-size: 16px;
          line-height: 30px;
          color: $verde;
          display: inline-block;
          cursor: pointer;

          @include tablet {
            padding: 0 40px;
          }

          :hover {
            opacity: 0.5;
          }
        }
      }

      &-img {
        background-image: url("../../assets/img/hero-dos-home-320.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 205px;
        width: 290px;
        margin: 0 auto;
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));

        @include tablet {
          background-image: url("../../assets/img/hero-dos-home-768.jpg");
          height: 350px;
          width: 500px;
        }

        @include laptop {
          background-image: url("../../assets/img/hero-dos-home-1152.jpg");
          height: 520px;
          width: 500px;
          margin: 0 100px 0 0;
        }

        @include desktop {
          background-image: url("../../assets/img/hero-dos-home-1440.jpg");
          margin: 0;
        }
      }
    }
  }

  &__seccion-que {
    color: $gris-fuerte;
    padding: 40px 16px 20px;
    position: relative;

    @include tablet {
      padding: 60px 0 40px;
    }

    @include laptop {
      padding-top: 0;
    }

    @include desktop {
      max-width: 1440px;
      margin: auto;
    }

    &__desaparece-laptop {
      @include laptop {
        display: none;
      }

      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;

        @include tablet {
          padding: 0 40px;
        }
      }

      &__p {
        font-weight: 300;
        margin-top: 20px;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;

        @include tablet {
          width: 410px;
          margin-bottom: 25px;
          padding: 0 40px;
          box-sizing: content-box;
        }
      }

      .inscribete {
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        color: $verde;
        display: inline-block;
        cursor: pointer;

        @include tablet {
          padding: 0 40px;
        }

        :hover {
          opacity: 0.5;
        }
      }
    }

    &__cuadrados {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
      grid-auto-flow: row;
      margin-top: 60px;

      @include tablet {
        grid-template-columns: 300px 300px;
        position: relative;
        width: 600px;
        margin: 100px auto 0;
      }

      @include laptop {
        grid-template-columns: 350px 350px;
        grid-template-rows: 250px;
        width: 710px;
        margin: 100px auto 0 100px;
        grid-gap: 10px;
      }

      &.cuadrado {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 25px;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        width: 100%;

        @include tablet {
          margin: 0 auto;
        }

        &__imagen {
          height: 50px;
          width: 80px;
          margin: 0 auto;
        }

        p {
          margin-top: 25px;
        }
      }

      &__curva-verde {
        @include tablet {
          background-image: url("../../assets/svg/curva-verde.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          bottom: 450px;
          right: -20px;
          width: 200px;
          height: 400px;
          mix-blend-mode: multiply;
        }

        @include laptop {
          width: 250px;
          height: 550px;
          bottom: 100px;
        }

        @include desktopExtra {
          display: none;
        }
      }

      &__curva-gris {
        @include tablet {
          background-image: url("../../assets/svg/curva-gris.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          bottom: 190px;
          right: -26px;
          width: 200px;
          height: 400px;
          mix-blend-mode: multiply;
        }

        @include laptop {
          width: 250px;
          height: 550px;
          bottom: -250px;
        }

        @include desktopExtra {
          display: none;
        }
      }
    }
  }

  &__seccion-mba {
    color: $gris-fuerte;
    padding: 40px 16px 40px;

    @include tablet {
      padding: 60px 40px 40px;
    }

    @include laptop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px 40px;
    }

    @include desktop {
      justify-content: space-evenly;
      padding-left: 260px;
      max-width: 1440px;
      margin: auto;
    }

    &__inscribete {
      @include laptop {
        width: 405px;
      }

      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
      }

      p {
        font-weight: 300;
        margin-top: 20px;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;

        @include tablet {
          width: 374px;
          margin-bottom: 25px;
        }
      }

      .inscribete {
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        color: $verde;
        display: inline-block;
        cursor: pointer;

        :hover {
          opacity: 0.5;
        }
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include tablet {
        align-items: flex-start;
      }

      @include laptop {
        :nth-child(1) {
          align-self: flex-end;
        }

        :nth-child(2) {
          align-self: flex-end;
          margin-right: 90px;
        }

        :nth-child(3) {
          align-self: flex-start;
        }

        :nth-child(4) {
          align-self: flex-end;
          margin-right: 30px;
        }

        :nth-child(5) {
          align-self: flex-start;
          margin-bottom: 40px;
        }
      }

      &-card {
        margin-top: 25px;
        padding: 10px;
        width: 100%;
        background: $blanco;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        @include tablet {
          display: inline-block;
          width: auto;
          margin-top: 40px;
          height: 50px;
        }

        :hover {
          opacity: 0.5;
        }

        &__span {
          @include tablet {
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            vertical-align: middle;
          }
        }

        .flecha {
          margin-right: 10px;
          flex-shrink: 0;

          @include tablet {
            margin-left: 20px;
            height: 17px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  &__seccion-tenemos {
    color: $gris-fuerte;

    @include tablet {
      margin-top: -1px;
      padding-top: 60px;
    }

    @include desktop {
      max-width: 1440px;
      margin: auto;
    }

    h3 {
      font-weight: 500;
      font-size: 30px;
      line-height: 30px;
      padding: 0 16px 0;

      @include tablet {
        padding: 0 40px 0;
      }

      @include laptop {
        padding-left: 100px;
      }

      @include desktop {
        padding-left: 150px;
        max-width: 1440px;
        margin: auto;
      }
    }

    &-p {
      font-weight: 300;
      margin-top: 20px;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;
      padding: 0 16px 0;

      @include tablet {
        padding: 0 80px 0 40px;
      }

      @include laptop {
        padding-left: 100px;
        width: 850px;
      }

      @include desktop {
        box-sizing: content-box;
        padding-left: 150px;
        width: 641px;
      }
    }

    &__fondo {
      padding: 20px 16px 160px;

      @include tablet {
        padding: 20px 0 150px;
      }

      @include tablet {
        position: relative;
      }

      &__curva-verde {
        display: none;

        @include laptop {
          display: block;
          background-image: url("../../assets/svg/curva-verde-izquierda.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          bottom: 100px;
          left: -50px;
          width: 382px;
          height: 384px;
        }

        @include desktopExtra {
          display: none;
        }
      }

      &__curva-gris {
        display: none;

        @include laptop {
          display: block;
          background-image: url("../../assets/svg/curva-gris-izquierda.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          bottom: -260px;
          left: -100px;
          width: 500px;
          height: 470px;
        }

        @include desktopExtra {
          display: none;
        }
      }

      &__cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include tablet {
          padding: 0 40px;
        }

        @include laptop {
          padding: 0 100px;
        }

        @include desktop {
          padding: 0 150px;
        }

        > :nth-child(2) {
          @include desktop {
            justify-content: flex-end;
          }
        }

        &__card-dupla {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          @include laptop {
            flex-direction: row;
            justify-content: flex-start;
          }

          @include desktop {
            justify-content: flex-start;
          }

          :nth-child(1) {
            @include tablet {
              margin-right: 210px;
            }

            @include laptop {
              margin-right: 0;
            }
          }

          :nth-child(2) {
            @include tablet {
              margin-left: 210px;
            }

            @include laptop {
              margin-left: 25px;
            }
          }

          &__card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 190px;
            width: 100%;
            max-width: 605px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            color: $blanco;
            padding: 0 0px;
            font-size: 20px;
            line-height: 20px;
            font-weight: bold;
            margin-bottom: 25px;
            position: relative;

            &--mask {
              background-color: rgba(0, 0, 0, 0.4);
              position: absolute;
              justify-content: center;
              align-items: center;
              display: flex;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            &.uno {
              background-image: url("../../assets/img/example-card-uno.jpg");
            }

            &.dos {
              background-image: url("../../assets/img/example-card-dos.jpg");
            }

            &.tres {
              background-image: url("../../assets/img/example-card-tres.jpg");
            }

            &.cuatro {
              background-image: url("../../assets/img/example-card-cuatro.jpg");
            }

            &.cinco {
              background-image: url("../../assets/img/example-card-cinco.jpg");
            }

            @include tablet {
              width: 488px;
              height: 300px;
            }

            @include laptop {
              // width: 455px;
            }

            @include desktop {
              justify-content: center;
              // width: 515px;
            }
          }
        }
      }

      &__profesores-cards {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 340px);
        justify-content: center;
        align-items: center;
        grid-gap: 60px;
        grid-auto-flow: row;
        padding: 60px 0 0;

        @include tablet {
          grid-template-columns: repeat(9, 1fr);
          grid-template-rows: repeat(6, 110px);
          padding: 0 40px;
          margin-top: 75px;
          grid-gap: 0;
        }

        @include laptop {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding-top: 100px;
          margin-top: 0;
          position: relative;
          padding: 135px 100px 0;
          &.topped {
            padding: 0;
          }
        }

        div:nth-of-type(3) {
          @include laptop {
            margin-top: 300px;
          }
        }

        &__profesores {
          text-align: center;
          color: $blanco;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @include tablet {
            align-items: flex-end;
            text-align: right;
            align-self: flex-end;
          }

          h4 {
            font-weight: 300;
            font-size: 30px;
            line-height: 30px;
          }

          &-p {
            margin-top: 25px;
            margin-bottom: 25px;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;

            @include tablet {
              line-height: 30px;
              padding: 0;
            }
          }

          .inscribete {
            text-decoration: none;
            font-weight: 700;
            font-size: 16px;
            line-height: 30px;
            color: $verde;
            margin-bottom: 25px;
            display: inline-block;
            cursor: pointer;

            :hover {
              opacity: 0.5;
            }
          }

          &.grid-uno {
            @include tablet {
              grid-column: 5 / 10;
              grid-row: span 2;
              align-self: start;
            }

            @include laptop {
              top: 0px;
              right: 100px;
              position: absolute;
              width: 400px;
              &.topped {
                top: -141px;
              }
            }
          }
        }
      }
    }
  }
}
