@import "../../InQba.scss";

.admin-modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;

  &__container {
    position: relative;
    padding: 24px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    width: 524px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 7px;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &__alta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title,
  &__subtitle {
    width: 100%;
    display: block;
    text-align: center;
  }

  &__title {
    width: 275px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: auto auto 38px;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 24px;
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__field {
    display: flex;
    flex-direction: column;
    width: 404px;
    margin: auto auto 24px;
  }

  &__checkbox {
    display: flex;
    margin: auto;
    margin-bottom: 30px;
    align-items: center;
    input[type="checkbox"] {
      padding: 19px;
      transform: scale(1.5);
    }
    label {
      margin-right: 5px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__label {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &__buttons {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }

  &__button {
    background-color: $negro;
    color: $blanco;
    border-radius: 30px;
    font-family: $montserrat;
    border: none;
    padding: 10px 14px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    &.mr {
      margin-right: 10px;
    }
    &.--white {
      background-color: $blanco;
      color: $negro;
      border: 2px solid $negro;
    }
  }

  &__input {
    border: 1px solid #c4c4c4;
    font-size: 16px;
    padding: 7px;
  }
}
