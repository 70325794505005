@import "../../InQba.scss";

.hero {
  width: 100vw;
  height: 450px;
  margin-top: 99px;
  padding: 177px 16px 40px;
  background: $verde;
  position: relative;

  @include tablet {
    margin-top: 130px;
    padding-left: 40px;
  }

  @include tablet {
    padding-left: 100px;
  }

  .hero__h1 {
    font-size: 50px;
  }

  .hero__p {
    @include tablet {
      margin-right: 310px;
    }

    @include laptop {
      margin-right: 630px;
    }

    @include desktop {
      margin-right: 730px;
    }
  }

  .hero__imagen {
    display: none;

    @include tablet {
      display: block;
      background-image: url("../../assets/img/hero-dos-home-320.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 310px;
      height: 430px;
      position: absolute;
      right: 0;
      bottom: -40px;
      z-index: 2;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
    }

    @include laptop {
      width: 500px;
      height: 430px;
      right: 100px;
    }

    @include desktop {
      right: 140px;
    }
  }
}

.contenedor-profesores {
  padding: 30px 16px;
  background-color: #f6f6f6;

  @include tablet {
    padding: 100px 42px 70px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    position: relative;
  }

  @include laptop {
    padding: 100px 30px 70px;
  }

  @include desktop {
    @media (min-width: 1440px) and (max-width: 1463px) {
      padding-right: 230px;
    }
    padding-right: 260px;
    padding-left: 142px;
  }

  &__curva-verde {
    @include tablet {
      background-image: url("../../assets/svg/curva-verde.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -55px;
      right: -55px;
      width: 400px;
      height: 600px;
      mix-blend-mode: multiply;
    }

    @include desktopExtra {
      display: none;
    }
  }

  &__curva-gris {
    @include tablet {
      background-image: url("../../assets/svg/curva-gris.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -400px;
      right: -90px;
      width: 400px;
      height: 600px;
      mix-blend-mode: multiply;
    }

    @include desktopExtra {
      display: none;
    }
  }
}
