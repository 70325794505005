.star-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  svg {
    width: 16px;
    justify-self: center;
    align-self: center;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}
