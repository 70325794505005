@import "../../InQba.scss";

.header {
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 16px;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    transition: background-color 0.3s cubic-bezier(0.37, 0, 0.63, 1);

    @include tablet {
      padding: 25px 40px;
    }

    @include laptop {
      padding: 25px 24px;
    }

    @include desktop {
      padding: 25px 140px;
    }

    &.blanco {
      background-color: $blanco;
    }

    a {
      .logo {
        width: 153px;

        @include tablet {
          width: 212px;
        }
      }
    }

    div.menu-horizontal {
      display: none;

      @include laptop {
        display: block;
      }

      ul {
        margin: 0;
        display: flex;
        flex-direction: row;
        text-align: center;

        li {
          list-style: none;
          margin-right: 10px;
          padding: 0;
          cursor: pointer;
          transition: 0.2s linear;

          &:hover {
            transform: scale(1.05);
          }

          @include laptop {
            margin-right: 20px;
          }

          a {
            text-decoration: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            color: $blanco;
            position: relative;

            &.active::after {
              position: absolute;
              width: 100%;
              content: "";
              bottom: -10px;
              left: 0;
              border-bottom: 4px solid $verde;
            }
            &.mba {
              &.active::after {
                border-bottom: 4px solid $amarillo;
              }
            }

            &.gris {
              color: $gris-fuerte;
            }
          }
        }
      }
    }

    div.menu-dropdown {
      @include laptop {
        display: none;
      }

      img {
        margin: 0;
        height: 30px;
        width: 30px;

        @include tablet {
          height: 45px;
          width: 45px;
        }
      }
    }
  }

  nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;

    div.menu-abierto-primero {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 16px;
      align-items: center;
      background-color: $blanco;

      @include tablet {
        padding: 30px 40px;
      }

      .logo {
        height: auto;
        width: 153px;
        padding: 0;

        @include tablet {
          width: 212px;
        }
      }

      img {
        padding: 0;
        height: 25px;
        width: 25px;

        @include tablet {
          width: 40px;
          height: 40px;
        }

        &:hover {
          opacity: 0.8;

          cursor: pointer;
        }
      }
    }

    .linea-separatoria {
      margin: 0 auto;
      height: 2px;
      background-color: $blanco;
      padding: 0 5%;

      &-linea {
        border-top: 2px solid $gris;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0 16px;
      background-color: $blanco;

      @include tablet {
        padding: 0 105px;
      }

      li {
        padding: 16px 0;
        border-top: 1px solid $gris-claro;

        @include tablet {
          padding-left: 25px;
        }

        &:last-child {
          border-bottom: none;
          padding: 16px 16px 90px 0;

          @include tablet {
            padding-left: 25px;
          }
        }

        &:first-child {
          border-top: none;
          background-color: transparent;
          padding-bottom: 20px;
        }

        a {
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 21px;
          text-align: right;
          color: $gris-fuerte;
          text-decoration: none;
        }
      }
    }

    div.overlay {
      height: 100vh;
      width: 100vw;
      backdrop-filter: blur(6px);
    }
  }
}
