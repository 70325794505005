@import "../../InQba.scss";

.eventos {
  background-color: #f6f6f6;
  .hero {
    background: $negro;

    @include tablet {
      padding-left: 40px;
    }

    @include laptop {
      padding-left: 100px;
    }

    &__imagen {
      @include tablet {
        background-image: url("../../assets/img/INQBA_Eventos768px.jpg");
      }
      @include laptop {
        background-image: url("../../assets/img/INQBA_Eventos1152.jpg");
        width: 498px;
        height: 430px;
      }
      @include desktop {
        background-image: url("../../assets/img/INQBA_Eventos1440px.jpg");
      }
    }
  }

  &__content {
    @include tablet {
      background-image: url("./ornamento.svg"), url("./ornamento-2.svg");
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: 212px, 424px;
    }
  }

  &__container {
    position: relative;
    padding: 40px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @include tablet {
      align-items: flex-start;
      padding: 100px 0px;
      width: 708px;
      margin: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include laptop {
      width: calc(1152px - 80px);
    }
    @include desktop {
      width: calc(1440px - 132px);
    }
  }
  &__card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    @include tablet {
      margin: 0 20px 40px 0;
      width: 344px;
      &:nth-child(2n) {
        margin: 0 0 40px;
      }
    }
    @include laptop {
      margin: 0 20px 40px 0;
      &:nth-child(2n) {
        margin: 0 20px 40px 0;
      }
      &:nth-child(3n) {
        margin: 0 0 40px;
      }
    }
    @include desktop {
      width: 420px;
      margin: 0 24px 40px 0;
      &:nth-child(2n) {
        margin: 0 24px 40px 0;
      }
      &:nth-child(3n) {
        margin: 0 0 40px;
      }
    }
    &__button {
      width: 162px;
      height: 45px;
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color: $blanco;
      border-radius: 7px;
      background-color: $verde;
      border: none;
      font-family: $montserrat;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      cursor: pointer;

      @include tablet {
        width: 194px;
        height: 54px;
      }
      @include desktop {
        width: 238px;
        height: 66px;
      }
    }
  }
}

.eventos-card {
  text-align: center;
  width: 290px;
  height: 411px;
  background-color: transparent;
  margin-bottom: 30px;
  z-index: 2;
  perspective: 1000px;

  .flip {
    transform: rotateY(180deg);
  }

  @include tablet {
    width: 344px;
    height: 491px;
  }

  @include desktop {
    width: 420px;
    height: 600px;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &__back,
  &__front {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 7px;
    border: 1px solid #d7d7dd;
  }

  &__front {
    background-color: #f6f6f6;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    span {
      text-align: center;
    }

    &:hover {
      background-color: $blanco;
      border: none;
    }
  }

  &__back {
    background-color: $blanco;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    transform: rotateY(180deg);
    padding: 10px;

    @include tablet {
      padding: 24px 30px;
    }

    &-scrollable {
      justify-content: start;
      max-height: 350px;
      overflow: auto;
      @include tablet {
        max-height: 430px;
      }
      @include desktop {
        max-height: 520px;
      }
    }
    &-nombre {
      margin: 0;
      line-height: 20px;
      font-size: 18px;
      color: $gris-fuerte;
      font-weight: bold;
      align-self: flex-start;
      @include laptop {
        font-size: 20px;
      }
    }
    &-register {
      color: $verde;
      text-align: left;
      align-self: flex-start;
      margin-top: 21px;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0;
      cursor: pointer;
      @include laptop {
        font-size: 16px;
      }
    }
    &-line {
      width: 100%;
      margin: 24px 0 0;
    }
    &-subtitle {
      color: $amarillo;
      align-self: flex-start;
      font-weight: 300;
      margin-top: 24px;
      text-align: left;
    }
    &-ponente {
      margin-top: 10px;
      font-weight: bold;
      color: $gris-fuerte;
      align-self: flex-start;
      text-align: left;
      @include laptop {
        font-size: 18px;
      }
    }
    &-descripcion {
      margin-top: 10px;
      color: $gris-fuerte;
      align-self: flex-start;
      text-align: left;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
    align-self: flex-start;
    &Item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        margin-right: 12px;
      }
    }
    &Text {
      font-family: $roboto;
      font-weight: 300;
      line-height: 21px;
      text-align: left;
      @include laptop {
        font-size: 18px;
      }
    }
  }

  &__linea {
    width: 110px;
    border-bottom: 3px solid $verde;
    margin-top: 25px;
  }

  &__foto {
    margin: 0;
    height: 273px;
    width: 100%;
    background-image: url("../../assets/img/foto-prueba-blanca.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include tablet {
      height: 327px;
    }
    @include desktop {
      height: 400px;
    }
  }

  &__estrellas {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 15px;
    width: 100px;
    margin-top: 25px;
  }

  &__nombre {
    margin-top: 10px;
    color: $gris-fuerte;
    line-height: 20px;
    font-family: $roboto;
    font-size: 18px;
    @include tablet {
      font-size: 20px;
      margin-top: 20px;
    }
    @include desktop {
      margin-top: 24px;
    }
  }

  &__ponente {
    margin-top: 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: $gris-fuerte;
    @include tablet {
      font-size: 18px;
      line-height: 21px;
    }
    @include desktop {
      margin-top: 24px;
    }
  }

  &__perfil-back {
    margin-top: 25px;
    font-size: 14px;
    color: $gris-fuerte;
    word-wrap: break-word;
    max-width: 100%;
  }

  &__cursos {
    margin-top: 15px;
    color: $gris-fuerte;
  }

  &__press-icon {
    height: 17.45px;
    width: 17.45px;
    margin-right: 5px;
  }

  &__press-icon-back {
    height: 17.45px;
    width: 17.45px;
    margin-top: 25px;
  }

  &__ver-mas {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    @include tablet {
      font-size: 16px;
      margin-top: 22px;
    }
    @include desktop {
      margin-top: 27px;
    }
  }

  &__refresh-arrows {
    height: 24px;
    margin-top: 15px;
    width: 24px;
    cursor: pointer;
  }
}
