@import "../../InQba.scss";

.unique-profesores {
  padding-top: 60px;
  @include laptop {
    display: flex;
    align-items: center;
    width: 1102px;
    margin: auto;
  }

  &__title {
    color: $gris-fuerte;
    padding: 0 16px;
    @include tablet {
      padding: 0;
      width: 688px;
      margin: auto;

      h3 {
        width: 405px;
        margin: 0;
      }
    }
    @include laptop {
      padding: 0 24px 60px;
      width: 405px;
      margin: 0;

      h3 {
        width: auto;
      }
    }
  }

  &__carousel {
    padding-top: 30px;
    overflow: hidden;
    width: 100%;
    margin: auto;
    @include tablet {
      padding-top: 60px;
    }
    @include laptop {
      width: 688px;
      margin: 0;
    }
  }

  &__slider {
    margin: auto;
    width: 288px;
    display: flex;
    flex-direction: row;
    transform: translateX(calc((100vw + 288px) * -1));
    height: 427px;

    @include tablet {
      min-width: 688px;
      width: 688px;
      transform: translateX(calc((100vw + 688px) * -1));
    }
    // @include laptop {
    //   width: 818px;
    //   transform: translateX(calc((100vw + 818px) * -1));
    // }
    // @include desktop {
    //   width: 1239px;
    //   transform: translateX(calc((100vw + 1239px) * -1));
    // }
  }

  &__item {
    margin: 10px 100vw 0 0;
    min-width: 288px;
    width: 288px;
    background-color: transparent;

    @include tablet {
      min-width: 330px;
      width: 330px;
      margin-right: 28px;

      &:nth-child(2n) {
        margin: 10px 100vw 0 0;
      }
    }
    // @include laptop {
    //   margin: 10px 24px 0 0;
    //   &:nth-child(2n) {
    //     margin: 10px 100vw 0 0;
    //   }
    // }
    // @include desktop {
    //   &:nth-child(2n) {
    //     margin: 10px 24px 0 0;
    //   }

    //   &:nth-child(3n) {
    //     margin: 10px 100vw 0 0;
    //   }
    // }

    &.spacer {
      opacity: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 86px;
    margin: auto;
    padding-top: 30px;
    @include tablet {
      padding-top: 60px;
    }
    button {
      background: $gris;
      border: none;
      width: 38px;
      height: 38px;
      padding: 0;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin: auto 12px auto auto;
      }
      &:first-child {
        svg {
          transform: rotate(180deg);
          margin: auto auto auto 12px;
        }
      }
    }
  }
}
