@import "../../InQba.scss";

.unique-info {
  padding: 45px 16px 0;
  &.green {
    svg {
      fill: $verde;
      path {
        fill: $verde;
      }
    }
  }
  @include tablet {
    width: 686px;
    margin: auto;
    padding: 60px 0 0;
  }

  @include laptop {
    width: 804px;
    padding: 105px 0 0;
  }
  @include desktop {
    width: 1136px;
  }

  &__item {
    margin-bottom: 24px;
    @include tablet {
      display: flex;
      align-items: flex-start;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      margin-left: 12px;
      color: $gris-fuerte;
      display: block;
    }
  }
  &__description {
    font-size: 20px;
    line-height: 30px;
    color: $gris-fuerte;
    display: block;
    font-weight: 300;
    margin: 10px 0 0 42px;
    @include tablet {
      margin: 0 0 0 10px;
    }
  }
}
