.admin-diplomados {
  padding: 30px 40px;
  &__titulo {
    text-align: left;
  }
  &__content {
    margin-left: 20px;
  }
  &__subtitulo {
    font-weight: 500;
  }

  &__categorias {
    position: relative;
    min-height: 40px;
    margin-top: 24px;
    &--title {
      font-weight: normal;
    }
    &--add {
      position: absolute;
      top: 0;
      bottom: auto;
      left: auto;
      right: 0;
    }
  }
}

.diplomados-page {
  padding: 60px 40px;
  &__otros {
    .collection-list {
      margin-top: 30px;
    }
  }
  &__listSelect {
    margin-top: 30px;
  }
}
