@import "../InQba.scss";

#contenedor-admin {
  display: grid;
  grid-template-columns: 310px auto;
  margin: 0px;
  height: 100vh;
  overflow: hidden;
  button {
    font-family: $montserrat;
  }

  div.wrap-vertical {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    display: block;

    .barra-superior {
      display: flex;
      height: 70px;
      justify-content: flex-end;
      background: #ffffff;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      align-items: center;
      border-bottom: 1px solid #c4c4c4;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 150px;
  text-align: center;
  margin-right: 30px;

  .person-icon {
    background-image: url("../../src/assets/svg/user.svg");
    width: 30px;
    height: 30px;
    float: right;
    margin-right: 18px;
  }

  &:hover {
    .dropdown-content {
      display: block;
    }
  }

  .dropdown-content {
    background-color: #f7f7f7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: none;
    list-style: none;
    position: absolute;
    width: 135px;
    right: 0;
    top: 30px;
    z-index: 1;
    text-align: center;
    margin: 0;
    padding: 30px 0 10px;

    li {
      position: relative;
      color: #000000;
      width: 95%;
      height: auto;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.15px;
      margin: auto;

      &:hover {
        background-color: black;

        a,
        button {
          color: white;
          padding: 0;
          outline: none;
        }
      }

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 5px;
        margin-bottom: 10px;
      }

      a,
      button {
        text-decoration: none;
        min-width: 135px;
        padding: 0;
        display: block;
        color: black;
      }
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    div {
      border-bottom: 1px solid #dadada;
      margin: 10px auto 0;
      width: 80%;
    }
  }
}

.central {
  margin: auto;
  height: fit-content;
  height: calc(100vh - 70px);
  overflow: auto;

  .default-autoadmin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 85%;

    h1 {
      width: 270px;
      text-align: center;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 60px;
      margin-top: 0;
    }

    img {
      width: 250px;
      height: auto;
    }
  }
}

//Toasts

.react-toast-notifications__toast {
  z-index: 99999;
  > :first-child {
    width: 66px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 66px;

    &::after {
      height: 46px;
      width: 46px;
      content: "";
      position: relative;
      top: 0;
      z-index: 99999;
      background-color: white;
      left: 0;
      display: block;
      border-radius: 100%;
      background-image: url(../assets/svg/toast-logo.svg);
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
    }

    svg {
      display: none !important;
    }
  }
}

.react-toast-notifications__toast--error {
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #ff6e67;
    }

    &::after {
      content: "";
      position: relative;
      top: 0;
      z-index: 99999;
      background-color: white;
      left: 0;
      display: block;
      border-radius: 100%;
      background-image: url(../assets/svg/toast-x-circle.svg);
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
    }
  }

  :nth-child(2) {
    color: #ff6e67;
  }

  :nth-child(3) {
    color: #050505;
    opacity: 1;
  }
}

.react-toast-notifications__toast--warning {
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #f5be30;
    }

    &::after {
      content: "";
      position: relative;
      top: 0;
      z-index: 99999;
      background-color: white;
      left: 0;
      display: block;
      border-radius: 100%;
      // background-image: url(./assets/images/alert-triangle.svg);
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center;
    }
  }

  :nth-child(2) {
    color: #f5be30;
  }

  :nth-child(3) {
    color: #050505;
    opacity: 1;
  }

  &.css-fwfomi-ToastElement {
    color: #050505;
  }
}

.react-toast-notifications__toast--info {
  z-index: 99999;
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #406aea;
    }
  }

  :nth-child(2) {
    background-color: #d6e8ff;
    color: #0070ff;
  }

  :nth-child(3) {
    background-color: #d6e8ff;
    opacity: 1;
    color: #050505;
  }
}

.react-toast-notifications__toast--success {
  z-index: 99999;
  > :first-child {
    background-color: white;

    .react-toast-notifications__toast__countdown {
      background-color: #31ac78;
    }
  }

  :nth-child(2) {
    background-color: #e9fff2;
  }

  :nth-child(3) {
    opacity: 1;
    color: #050505;
  }
}
