@import "../../InQba.scss";

.admin-login {
  min-height: 100vh;
  background-image: url("../../assets/img/hero-home-1440.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__container {
    width: 524px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__form {
    padding: 24px 0;
    margin-top: 80px;
    width: 100%;
    background-color: $blanco;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      font-family: $montserrat;
    }

    input {
      font-family: $montserrat;
      width: 404px;
      border: 1px solid #c4c4c4;
      font-size: 14px;
      padding: 7px;
      margin-bottom: 24px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &__link {
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    font-family: $montserrat;
  }

  &__authForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    position: relative;

    &.m-0 {
      margin-bottom: 0;
    }

    svg {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    button {
      background: #000000;
      cursor: pointer;
      font-family: $montserrat;
      border-radius: 30px;
      color: $blanco;
      border: none;
      height: 40px;
      padding: 0 16px;
      font-size: 16px;
    }
  }
}
