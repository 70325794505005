@import "../../InQba.scss";

.tenemos {
  color: $gris-fuerte;

  @include tablet {
    margin-top: -1px;
    padding-top: 60px;
  }

  @include desktop {
    max-width: 1440px;
    margin: auto;
  }

  h3 {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    padding: 0 16px 0;

    @include tablet {
      padding: 0 40px 0;
    }

    @include laptop {
      padding-left: 100px;
    }

    @include desktop {
      padding-left: 150px;
      max-width: 1440px;
      margin: auto;
    }
  }

  &-p {
    font-weight: 300;
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
    padding: 0 16px 0;

    @include tablet {
      padding: 0 80px 0 40px;
    }

    @include laptop {
      padding-left: 100px;
      width: 850px;
    }

    @include desktop {
      box-sizing: content-box;
      padding-left: 150px;
      width: 641px;
    }
  }

  &__fondo {
    padding: 20px 16px 160px;

    @include tablet {
      padding: 20px 0 150px;
    }

    @include tablet {
      position: relative;
    }

    &__cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include tablet {
        padding: 0 40px;
      }

      @include laptop {
        padding: 0 100px;
      }

      @include desktop {
        padding: 0 150px;
      }

      > :nth-child(2) {
        @include desktop {
          justify-content: flex-end;
        }
      }

      &__card-dupla {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        @include laptop {
          flex-direction: row;
          justify-content: center;
          &.non {
            justify-content: flex-start;
          }
        }

        @include desktop {
          justify-content: flex-start;
        }

        :nth-child(1) {
          @include tablet {
            margin-right: 210px;
          }

          @include laptop {
            margin-right: 0;
          }
        }

        :nth-child(2) {
          @include tablet {
            margin-left: 210px;
          }

          @include laptop {
            margin-left: 25px;
          }
        }

        &__card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 190px;
          width: 100%;
          max-width: 605px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          text-align: center;
          color: $blanco;
          padding: 0 0px;
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;
          margin-bottom: 25px;
          cursor: pointer;
          position: relative;
          &.spacer {
            opacity: 0;
          }

          &--mask {
            background-color: rgba(0, 0, 0, 0.4);
            position: absolute;
            justify-content: center;
            align-items: center;
            display: flex;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &.uno {
            background-image: url("../../assets/img/example-card-uno.jpg");
          }

          &.dos {
            background-image: url("../../assets/img/example-card-dos.jpg");
          }

          &.tres {
            background-image: url("../../assets/img/example-card-tres.jpg");
          }

          &.cuatro {
            background-image: url("../../assets/img/example-card-cuatro.jpg");
          }

          &.cinco {
            background-image: url("../../assets/img/example-card-cinco.jpg");
          }

          &:hover {
            transform: scale(1.05);
            transition: all 0.5s;
          }

          @include tablet {
            width: 488px;
            height: 300px;
          }

          @include laptop {
            // width: 455px;
          }

          @include desktop {
            justify-content: center;
            // width: 515px;
          }
        }
      }
    }

    &__profesores-cards {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 340px);
      justify-content: center;
      align-items: center;
      grid-gap: 60px;
      grid-auto-flow: row;
      padding: 60px 0 0;

      @include tablet {
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(6, 110px);
        padding: 0 40px;
        margin-top: 75px;
        grid-gap: 0;
      }

      @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        margin-top: 0;
        position: relative;
        padding: 135px 100px 0;
      }

      div:nth-of-type(3) {
        @include laptop {
          margin-top: 300px;
        }
      }

      &__profesores {
        text-align: center;
        color: $gris-fuerte;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include tablet {
          align-items: flex-end;
          text-align: right;
          align-self: flex-end;
        }

        h4 {
          font-weight: 300;
          font-size: 30px;
          line-height: 30px;
        }

        &-p {
          margin-top: 25px;
          margin-bottom: 25px;
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;

          @include tablet {
            line-height: 30px;
            padding: 0;
          }
        }

        .inscribete {
          text-decoration: none;
          font-weight: 700;
          font-size: 16px;
          line-height: 30px;
          color: $verde;
          margin-bottom: 25px;
          display: inline-block;
          cursor: pointer;
          &.gold {
            color: $amarillo;
          }

          :hover {
            opacity: 0.5;
          }
        }

        &.grid-uno {
          @include tablet {
            grid-column: 5 / 10;
            grid-row: span 2;
            align-self: start;
          }

          @include laptop {
            top: 0px;
            right: 100px;
            position: absolute;
            width: 400px;
          }
          &.isMBA {
            top: -100px;
          }
          &.topped {
            top: 30px;
          }
        }
      }
    }
  }
}
