@import "../../InQba.scss";

.boton-agregar {
  border: none;
  color: white;
  background: $verde;
  padding: 10px 15px 7px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  font-size: 16px;
  line-height: 20px;
  width: 150px;
  cursor: pointer;
  outline: none;

  .boton-agregar__span {
    font-size: 40px;
    margin-bottom: 2px;
    height: 21px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 300;
  }
}

.input-group {
  justify-content: flex-start;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }

  .input-group__input {
    width: 405px;
    height: 33px;
    padding: 5px 10px;
    font-size: 14px;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
  }

  &__img {
    cursor: pointer;
    &.leftInfo {
      cursor: initial;
    }
    &.opaque {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .input-group--press {
    width: 27px;
    height: 27px;
    margin-right: 10px;
  }

  .input-group--bote {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
}

.cambios {
  justify-content: flex-start;
  width: 400px;
  height: 40px;
  background-color: $blanco;
  margin-bottom: 60px;
  margin-top: 10px;
  &.mb-0 {
    margin-bottom: 0;
  }

  .cambios__button {
    height: 40px;
    width: auto;
    border-radius: 30px;
    padding: 8px 10px 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: 2px solid #000000;
    outline: none;
    cursor: pointer;
  }

  .cambios--guardar {
    background: #000000;
    color: white;
    font-weight: bold;
  }

  .cambios--descartar {
    background: white;
    color: #000000;
    margin-left: 10px;
  }
}

.card {
  margin-top: 30px;
  button {
    outline: none;
  }
  &--more-margin-top {
    margin-top: 60px;
  }

  .card__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 10px;
    display: block;
  }

  .card__body {
    justify-content: flex-start;
    margin-top: 10px;

    .card__imagen {
      width: 190px;
      height: 110px;
      background-color: #c4c4c4;
      object-fit: cover;
      margin: 0;
    }

    .card__input {
      display: none;
    }
    .card__label {
      cursor: pointer;
      margin-left: 10px;
    }

    .card__subtitle {
      font-weight: 500;
      font-size: 16px;
      margin-left: 10px;
      line-height: 20px;
      cursor: pointer;
    }
  }

  .card__textarea {
    width: 405px;
    margin-top: 10px;
    resize: none;
    padding: 5px 10px;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .card__variante {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    min-height: 33px;

    .card__boton {
      font-family: $montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-top: 5px;
      margin-left: 30px;
      border: none;
      background-color: $blanco;
      cursor: pointer;
    }
  }
}

.rating {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  overflow: hidden;
  flex-direction: row-reverse;
  height: auto;
  margin-top: 10px;
  position: relative;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating > input {
  display: none;
}

.rating > label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: 0.3s;
  &.inRange {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  }
}

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.collection-item {
  min-height: 83px;
  position: relative;
  &__add {
    position: absolute;
    top: -5px;
    bottom: auto;
    left: auto;
    right: 0;
  }
  &__item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.line-break {
  border: 1px solid #000000;
  width: 100%;
  margin: 30px 0;
}

.collection-list {
  position: relative;
  min-height: 40px;
  &__add {
    position: absolute;
    top: -5px;
    bottom: auto;
    left: auto;
    right: 0;
  }
  &__title {
    font-weight: 500;
  }
  &__list {
    margin-top: 10px;
  }
  &__inputs {
    position: relative;
    min-height: 70px;
    margin-top: 30px;
  }
  &__item {
    display: flex;
    align-items: center;
    &:first-child {
      margin-top: 0;
    }
    margin-top: 10px;
    img {
      margin-left: 10px;
    }
  }
  &__select-wrapper {
    width: 405px;
    display: flex;
    align-items: center;
    border: 1px solid #c4c4c4;
    position: relative;
    img {
      position: absolute;
      right: 10px;
      pointer-events: none;
    }
    select {
      cursor: pointer;
      background-color: #fff;
      padding: 8px 16px;
      width: 100%;
      outline: none;
      border: none;
      display: block;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
      &::-ms-expand {
        display: none;
      }
    }
  }
}
.collection-item {
  &__textarea {
    width: 405px;
    margin-top: 10px;
    resize: none;
    padding: 5px 10px;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__list {
    margin: 30px 0;
  }

  &__otros {
    textarea {
      min-height: 70px;
    }
  }

  &__bulletList {
    position: relative;
    min-height: 40px;
  }

  &__bItem {
    margin-top: 30px;
    position: relative;
  }

  &__bTitle {
    margin: 30px 0 10px;
    justify-content: flex-start;
    span {
      display: block;
    }
    svg {
      cursor: pointer;
      margin-left: 317px;
    }
  }

  &__addBullet {
    position: absolute;
    right: 0;
    left: auto;
    top: 90px;
    display: block;
    border: 2px solid #abc60d;
    border-radius: 30px;
    background-color: #fff;
    padding: 10px 16px 8px;
    cursor: pointer;
  }
}

.collection-testimonios {
  position: relative;
  &__add {
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
    left: auto;
    bottom: auto;
  }
}
