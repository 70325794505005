/* Inician mixins */

$mobileMinusHeight: 699px;
$mobileMinusWidth: 330px;
$tablet: 768px;
$laptop: 1152px;
$desktop: 1440px;
$desktopExtra: 1455px;

@mixin mobileMinus {
  @media (max-height: #{$mobileMinusHeight}) and (max-width: #{$mobileMinusWidth}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin desktopExtra {
  @media (min-width: #{$desktopExtra}) {
    @content;
  }
}

/* Terminan mixins */

/* Inician fuentes */

$roboto: "Roboto";
$montserrat: "Montserrat";

/* Terminan fuentes */

/* Inician colores */

$negro: #000000;
$blanco: #ffffff;
$gris: #95979a;
$gris-claro: #e5e5e5;
$gris-fuerte: #6b6b6b;
$verde: #abc60d;
$amarillo: #dfc747;

/* Terminan colores */
