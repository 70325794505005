@import "InQba.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

/* Inicia override */

.InQba {
  text-align: center;
}

html {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

body {
  font-family: $montserrat;
  margin: 0;
  background-color: #e5e5e5;
  overflow-x: hidden;

  &.gradient-home {
    background: linear-gradient(180deg, #f7f7f7 38.54%, #000000 83.33%);
  }
}

h1,
h2,
h3,
h4,
p,
span {
  font-family: $montserrat;
  margin: 0;
  letter-spacing: 1px;
}

.headline1 {
  font-family: $roboto;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
}

.headline2 {
  font-family: $montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.headline3 {
  font-family: $montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.subtitle1 {
  font-family: $roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.body {
  font-family: $montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.button-primary {
  font-family: $montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;
}

.caption1 {
  font-family: $roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.caption2 {
  font-family: $roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.caption3 {
  font-family: $montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.caption4 {
  font-family: $montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.caption5 {
  font-family: $montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.015em;
  text-align: center;
}

.caption6 {
  font-family: $montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

.shadow-hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
}

.white {
  color: $blanco;
}

.black {
  color: $negro;
}

.green {
  color: $verde;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Termina override */

.unique-page {
  background: #f6f6f6;
}
