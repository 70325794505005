@import "../../InQba.scss";

.carousel {
  position: relative;
  margin: 150px auto 0;
  padding: 0;
  width: 286px;

  @include tablet {
    width: 688px;
    padding: 0 62px;
    margin: 100px auto 0;
  }

  @include laptop {
    width: 730px;
    padding: 0 72px;
    margin: 160px 100px 0 auto;
  }

  @include desktop {
    padding: 0 100px;
    width: 770px;
  }

  @include desktopExtra {
    margin: 160px auto 0;
  }

  &__slide {
    align-items: flex-start;
  }

  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  &__testimonio {
    max-width: 566px;
    padding: 0;
  }

  &__nombre {
    margin-top: 25px;
  }

  &__puesto {
    margin-top: 25px;
  }

  &__prev,
  &__next {
    cursor: pointer;
    position: absolute;
    width: 38px;
    height: 38px;
    transition: 0.6s ease;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    border: none;
    background-image: url("../../assets/svg/carrusel-flecha-derecha.svg");
    margin-top: 60px;

    @include tablet {
      top: 25%;
    }

    @include tablet {
    }

    &:disabled {
      background-image: url("../../assets/svg/carrusel-flecha-izquierda.svg");
      transform: none;
    }
  }

  &__next {
    right: calc(50% - 42px);

    @include tablet {
      right: 0;
    }

    &:disabled {
      transform: rotateY(180deg);
    }
  }

  &__prev {
    left: calc(50% - 42px);

    @include tablet {
      left: 0;
    }

    transform: rotateY(180deg);
  }
}
