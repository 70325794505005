@import "../../InQba.scss";

.admin-contacto {
  height: 100%;
  width: 100%;
  padding: 30px 40px;

  &__contenedor-contacto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 60px auto 0 20px;

    .contenedor {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 auto 10px 0;

      &__input {
        height: 33px;
        width: 362px;
        margin-left: 10px;
        border: 1px solid #c4c4c4;
        background-color: $blanco;
        padding: 3px 10px;
      }

      &__imagen {
        height: 33px;
        width: 33px;
      }
    }
  }
}
